import "../../sass/core.scss";

import "./polyfills";

import config from "./config";
import { initSiteWide, initSiteWideOnReady } from "./sitewide";

/**
 * Run all site-wide code.
 *
 * @param {object} options - options to merge into the site config object
 */
export function initBase(options) {
  Object.assign(config, options);
  initSiteWide();
}

/**
 * Run all site-wide code that we want to execute after all of our scripts have loaded.
 */
export function initBaseOnReady() {
  initSiteWideOnReady();
}
