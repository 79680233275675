import requests, { ResponseError } from "../core/requests";

function updateDropdownMenuArrowStyle(
  notificationsContainer,
  firstVisibleNotification,
) {
  const dropdownArrow = document.querySelector(".notification-dropdown-arrow");

  if (firstVisibleNotification) {
    if (firstVisibleNotification.classList.contains("previously-seen")) {
      dropdownArrow.classList.add("previously-seen");
    } else {
      dropdownArrow.classList.remove("previously-seen");
    }
  } else {
    dropdownArrow.classList.remove("previously-seen");
  }
}

function updateNotificationsDropdown() {
  const notificationsContainers = document.querySelectorAll(".notifications-container");
  notificationsContainers.forEach((notificationsContainer) => {
    const visibleNotifications = notificationsContainer.querySelectorAll(
      ".notification-container:not(.read):not(.dismissed):not(.d-none)",
    );
    const emptyNotificationsMessage = notificationsContainer.querySelector(
      ".empty-notifications-message",
    );
    const loadMoreLinkContainer = notificationsContainer.querySelector(
      ".load-more-link-container",
    );

    let firstVisibleNotification = null;

    if (
      visibleNotifications.length ||
      !loadMoreLinkContainer.classList.contains("d-none")
    ) {
      emptyNotificationsMessage.classList.add("d-none");
      firstVisibleNotification = [...visibleNotifications][0];
    } else {
      emptyNotificationsMessage.classList.remove("d-none");
    }

    updateDropdownMenuArrowStyle(notificationsContainer, firstVisibleNotification);
  });
}

async function updateNotification(updateAction, updateUrl, notificationUUID) {
  const notificationContainers = document.querySelectorAll(
    `[data-uuid='${notificationUUID}']`,
  );

  if (updateAction === "dismissed") {
    notificationContainers.forEach((notificationContainer) =>
      notificationContainer.classList.add("dismissed", "d-none"),
    );
    updateNotificationsDropdown();
  } else if (updateAction === "read") {
    notificationContainers.forEach((notificationContainer) =>
      notificationContainer.classList.add("read", "d-none"),
    );
    updateNotificationsDropdown();
  }

  const data = {};
  data[updateAction] = true;

  try {
    // We don't care about response data so long as it's 200.
    const response = await requests.post(updateUrl, data);
    if (!response.ok) {
      throw new ResponseError(response);
    }
  } catch (err) {
    window.Rollbar.warn("Faile to dismiss/read notifications", err);

    if (updateAction === "dismissed") {
      notificationContainers.forEach((notificationContainer) =>
        notificationContainer.classList.remove("dismissed", "d-none"),
      );
      updateNotificationsDropdown();
    } else if (updateAction === "read") {
      notificationContainers.forEach((notificationContainer) =>
        notificationContainer.classList.remove("read", "d-none"),
      );
      updateNotificationsDropdown();
    }
  }
}

function markNotificationsAsSeenOnInitialMenuOpen() {
  const notificationTogglers = document.querySelectorAll(".notifications-toggler");

  notificationTogglers.forEach((notificationToggler) => {
    notificationToggler.addEventListener("click", () => {
      const unseenNotificationsDot = notificationToggler.querySelector(
        ".unseen-notifications-dot",
      );

      if (notificationToggler) {
        unseenNotificationsDot.classList.add("d-none");
        const unseenNotifications = document.querySelectorAll(
          ".notification-container:not(.previously-seen):not(.dismissed)",
        );
        const newlySeenNotifications = [...unseenNotifications].filter(
          (notification) => {
            return notification.dataset.counter < notification.dataset.window;
          },
        );
        newlySeenNotifications.map((notification) =>
          updateNotification(
            "seen",
            notification.dataset.url,
            notification.dataset.uuid,
          ),
        );
      }
    });
  });
}

function initLoadMoreLink() {
  const loadMoreLinks = document.querySelectorAll(".load-more-link");
  loadMoreLinks.forEach((loadMoreLink) => {
    const notificationsContainer = loadMoreLink.closest(".notifications-container");
    loadMoreLink.addEventListener("click", () => {
      const hiddenNotificationContainers = notificationsContainer.querySelectorAll(
        ".notification-container.d-none:not(.dismissed):not(.read)",
      );
      const nextNotifications = [...hiddenNotificationContainers].slice(
        0,
        loadMoreLink.dataset.window,
      );
      nextNotifications.map((notification) => {
        notification.classList.remove("d-none");
        return updateNotification(
          "seen",
          notification.dataset.url,
          notification.dataset.uuid,
        );
      });

      if (nextNotifications.length - loadMoreLink.dataset.window < 1) {
        loadMoreLink.closest(".load-more-link-container").classList.add("d-none");
      }
    });
  });
}

function initNotificationContainers() {
  const notificationContainers = document.querySelectorAll(".notification-container");
  notificationContainers.forEach((notificationContainer) => {
    const updateUrl = notificationContainer.getAttribute("data-url");
    const dismissLink = notificationContainer.querySelector(".dismiss-notification");
    dismissLink.addEventListener("click", () => {
      updateNotification("dismissed", updateUrl, notificationContainer.dataset.uuid);
    });

    const notification = notificationContainer.querySelector(".notification");
    notification.addEventListener("click", () => {
      updateNotification("read", updateUrl, notificationContainer.dataset.uuid);
    });
  });
}

export default function initNotifications() {
  markNotificationsAsSeenOnInitialMenuOpen();
  initLoadMoreLink();
  initNotificationContainers();
}
