import { animateCSS, isVisible } from "../core/utils";

export default function initNavigation() {
  // Clicking off of any of the menus, animate and put menus back to closed state.
  document.body.addEventListener("click", async (event) => {
    const toggler = event.target.closest(".el-navbar-desktop-toggler");
    const targetClasses = event.target.classList;

    if (
      !targetClasses.contains("dismiss-notification") &&
      !targetClasses.contains("load-more-link")
    ) {
      if (!toggler) {
        const desktopHamburgerToggler = document.querySelector(
          ".el-navbar-desktop-hamburger-toggler",
        );
        if (desktopHamburgerToggler) {
          desktopHamburgerToggler.classList.remove("active");
        }

        const navTogglerImage = document.querySelector(
          ".el-navbar-toggler img.rotate-180",
        );
        if (navTogglerImage) {
          navTogglerImage.classList.remove("rotate-180");
        }

        const navDropdown = document.querySelector(".el-nav-dropdown.d-block");
        if (navDropdown) {
          await animateCSS(navDropdown, "fadeOut", "400ms");
          navDropdown.classList.remove("d-block");
        }
      }
    }
  });

  // Clicking handler for any nav item with a drop down menu. (not including hover menu)
  const desktopTogglers = document.querySelectorAll(".desktop-toggler-container");
  desktopTogglers.forEach((desktopToggler) => {
    desktopToggler.addEventListener("click", async (event) => {
      const currentDropdownMenu = event.currentTarget.querySelector(".el-nav-dropdown");
      const targetClasses = event.target.classList;

      if (
        !targetClasses.contains("dismiss-notification") &&
        !targetClasses.contains("load-more-link")
      ) {
        const desktopHamburgerToggler = document.querySelector(
          ".el-navbar-desktop-hamburger-toggler",
        );
        if (desktopHamburgerToggler) {
          desktopHamburgerToggler.classList.remove("active");
        }

        const navDropdowns = [...document.querySelectorAll(".el-nav-dropdown")].filter(
          isVisible,
        );
        navDropdowns.forEach(async (navDropdown) => {
          await animateCSS(navDropdown, "fadeOut", "400ms");
          navDropdown.classList.remove("d-block");
        });

        const navTogglerImage = document.querySelector(
          ".el-navbar-toggler img.rotate-180",
        );
        if (navTogglerImage) {
          navTogglerImage.classList.remove("rotate-180");
        }

        if (isVisible(currentDropdownMenu)) {
          // When clicking on an open menu item
          await animateCSS(currentDropdownMenu, "fadeOut", "350ms");
          currentDropdownMenu.classList.remove("d-block");
        } else {
          // When clicking on a closed menu item
          currentDropdownMenu.classList.add("d-block");
          animateCSS(currentDropdownMenu, "fadeIn", "350ms");

          const toggler = event.currentTarget.querySelector(
            ".el-navbar-toggler img:not(.stationary)",
          );
          if (toggler) {
            toggler.classList.toggle("rotate-180");
          }

          const hamburgerToggler = event.currentTarget.querySelector(
            ".el-navbar-desktop-hamburger-toggler",
          );
          if (hamburgerToggler) {
            hamburgerToggler.classList.add("active");
          } else {
            document
              .querySelectorAll(".el-navbar-desktop-hamburger-toggler")
              .forEach((otherHamburgerToggler) => {
                otherHamburgerToggler.classList.remove("active");
              });
          }
        }
      }
    });
  });
}
