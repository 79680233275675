import Cookies from "js-cookie";

/**
 * A class encapsulating a Fetch HTTP Response error
 */
export class ResponseError extends Error {
  constructor(response) {
    const message = `HTTP ${response.status}: ${response.statusText}`;

    super(message);

    this.name = "Fetch HTTP Response Error";
    this.response = response;
  }
}

/**
 * Returns a FormData instance from a given object, FormData or HTMLFormElement. If
 * the parameter is a FormData, this function just returns it without modification.
 *
 * @param {object|HTMLFormElement|FormData} data - the data to build a FormData from
 * @returns {FormData} the generated FormData
 */
function buildFormData(data) {
  let formData;

  if (data instanceof HTMLFormElement) {
    formData = new FormData(data);
  } else if (data instanceof FormData) {
    formData = data;
  } else {
    formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
  }

  return formData;
}

/**
 * Conditionally add a CSRF token to the options for a fetch request.
 *
 * @param {object} options - the request options to read and mutate
 */
function conditionallyAddCSRF(options) {
  // Only add the CSRF token if we're in same-origin mode or the mode wasn't specified
  // (Our POST and DELETE requests operate in 'same-origin' mode when a mode isn't
  // specified) because we don't want to send our CSRF token to another domain.
  if (
    !Object.prototype.hasOwnProperty.call(options, "mode") ||
    options.mode === "same-origin"
  ) {
    options.headers = {
      ...options.headers,
      "X-CSRFToken": Cookies.get("csrftoken"),
    };
  }
}

/**
 * Return a Promise that resolves to the Response object representing the response to a
 * fetch request.
 *
 * TODO: Consider adding a header `'Accept': 'application/json'` if we always expect JSON
 *
 * @param {string} url - the URL to dispatch the request to.
 * @param {object} options - the options for the request
 * @returns {Promise<Response>} a Promise containing the Response
 */
function dispatchRequest(url, options = {}) {
  return fetch(url, options);
}

/**
 * Return a Promise that resolves to the Response object representing the response to a
 * GET fetch request.
 *
 * @param {string} url - the URL to GET
 * @param {object|URLSearchParams} params - the params for the GET request
 * @param {object} options - the options for the request
 * @returns {Promise<Response>} a Promise containing the Response
 */
function get(url, params = {}, options = {}) {
  const queryString =
    params instanceof URLSearchParams
      ? params.toString()
      : new URLSearchParams(params).toString();

  return dispatchRequest(queryString.length > 0 ? `${url}?${queryString}` : url, {
    method: "GET",
    ...options,
  });
}

/**
 * Return a Promise that resolves to the Response object representing the response to a
 * POST fetch request. The request operates in 'same-origin' mode by default. Request
 * data will be sent as FormData ('multipart/form-data').
 *
 * TODO: Add a way to send data as JSON ('application/json')
 * TODO: Add a way to send data as URLSearchParams ('application/x-www-form-urlencoded')
 *
 * @param {string} url - the URL to POST
 * @param {object|HTMLFormElement|FormData} data - the data to put in the body of the POST
 * @param {object} options - options for the request
 * @returns {Promise<Response>} a Promise containing the Response
 */
function post(url, data = {}, options = {}) {
  conditionallyAddCSRF(options);

  return dispatchRequest(url, {
    method: "POST",
    body: buildFormData(data),
    mode: "same-origin",
    ...options,
  });
}

/**
 * Return a Promise that resolves to the Response object representing the response to a
 * DELETE fetch request. The request operates in 'same-origin' mode by default.
 *
 * @param {string} url - the URL to DELETE
 * @param {object} options - options for the request
 * @returns {Promise<Response>} A promise representing the request. Resolves to a response object.
 */
function del(url, options = {}) {
  conditionallyAddCSRF(options);

  return dispatchRequest(url, {
    method: "DELETE",
    mode: "same-origin",
    ...options,
  });
}

const requests = {
  get,
  post,
  delete: del,
};

export default requests;
