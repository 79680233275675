import ClipboardJS from "clipboard";
import Popover from "bootstrap/js/dist/popover";
import Tooltip from "bootstrap/js/dist/tooltip";

/**
 * Return a Promise that resolves to a string representing the end of a CSS animation as
 * defined within sass/_animations.scss (this is meant to be used with our copy of
 * Animate.css).
 *
 * Examples:
 *   - Fade an element out after 1.5 seconds: animateCSS(element, 'fadeOut', '1.5s');
 *   - Fade an element out and do something once the animation completes:
 *     animateCSS(element, 'fadeOut').then(() => {
 *       // Do something after the animation
 *     });
 *
 * See https://animate.style/#javascript for the original implementation.
 *
 * @param {Element} element - the element to animate
 * @param {string} animation - the animation name to use (see _animations.scss)
 * @param {string} [duration] - the duration override of the animation (ex. '500ms')
 * @returns {Promise} A promise representing the animation's execution, which can be awaited
 *     for actions to take post-animation
 */
export function animateCSS(element, animation, duration = undefined) {
  // We create a Promise and return it
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    const animationName = `animate__${animation}`;

    if (duration) {
      element.style.setProperty("--animate-duration", duration);
    }

    element.classList.add("animate__animated", animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      element.classList.remove("animate__animated", animationName);
      if (duration) {
        element.style.removeProperty("--animate-duration");
      }
      resolve("Animation ended");
    }

    element.addEventListener("animationend", handleAnimationEnd, { once: true });
  });
}

/**
 * Function that returns a given callback function wrapped with a timeout.
 *
 * @param {Function} callback - function to execute after the timeout elapses
 * @param {number} [timeout] - how long to wait to execute the callback
 * @returns {Function} the created function (useful for cancelling)
 */
export function createFunctionWithTimeout(callback, timeout = 1000) {
  let called = false;

  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }

  setTimeout(fn, timeout);

  return fn;
}

/**
 * Return true if a given element is not visible. Otherwise, return false.
 *
 * @param {Element} element - the element to check the visibility of
 * @returns {boolean} whether or not the element is hidden
 */
export function isHidden(element) {
  return !(
    element.offsetWidth ||
    element.offsetHeight ||
    element.getClientRects().length
  );
}

/**
 * Return true if a given element is visible. Otherwise, return false.
 *
 * @param {Element} element - the element to check the visibility of
 * @returns {boolean} whether or not the element is visible
 */
export function isVisible(element) {
  return !!(
    element.offsetWidth ||
    element.offsetHeight ||
    element.getClientRects().length
  );
}

/**
 * Scroll to the top of a given Element with an optional additional offset. Optionally,
 * a containing Element can be scrolled instead of the global window variable.
 *
 * @param {Element} element - DOM Node to scroll to.
 * @param {number} [offset] - Additional offset to add.
 * @param {boolean} [instant] - Whether or not the animation should be instant
 * @param {Element} [base] - The Element to scroll
 */
export function scrollToElement(
  element,
  offset = 0,
  instant = false,
  base = document.scrollingElement,
) {
  base.scroll({
    top: base.scrollTop + element.getBoundingClientRect().top + offset,
    behavior: instant ? "instant" : "auto",
  });
}

/**
 * Given a submit button in a form, make it invisible and place a visible dummy
 * (non-submit) button in its place indicating loading behavior, including a white
 * spinner and customizable loading text.
 *
 * This function will pay attention to the following attributes of the button:
 * data-submit-loading-text: if provided, this text will be rendered on the dummy.
 * A value of ' ' (one space) will become '' (empty string)
 * and therefore only display the loading spinner. If an
 * empty string is initially provided (or nothing), then the
 * dummy text will be "Working..."
 * data-submit-protect-gray-spinner: if "true", the spinner rendered will be gray.
 * data-submit-protect-invert-spinner: if "true" the spinner will render as brand-
 * secondary blue.
 * CSS class btn-outline-secondary: if it has this CSS class, the same effect as
 * data-submit-protect-invert-spinner
 *
 * @param {HTMLButtonElement} button - the button element
 * @returns {HTMLButtonElement} the created dummy button element
 */
function swapButtonForDummy(button) {
  let loadingText =
    button.dataset.submitProtectLoadingText || button.innerText || "Working...";

  loadingText = loadingText === " " ? "" : loadingText;

  // Clone the button to make a dummy submit button that we can disable.
  const dummyButton = button.cloneNode();
  dummyButton.removeAttribute("id");
  dummyButton.removeAttribute("name");
  dummyButton.dataset.isDummy = true;
  dummyButton.setAttribute("disabled", true);
  if (dummyButton.classList.contains("btn")) {
    dummyButton.classList.add("disabled");
  }
  button.after(dummyButton);

  if (button.dataset.clicked) {
    let spinnerStatus;
    if (button.dataset.submitProtectGraySpinner) {
      spinnerStatus = `<div class="d-flex justify-content-center"><div class="spinner-border spinner-border-sm me-1 mt-1 text-default"></div>${loadingText}</div>`;
    } else if (
      button.dataset.submitProtectInvertSpinner ||
      button.classList.contains("btn-outline-secondary")
    ) {
      spinnerStatus = `<div class="d-flex justify-content-center"><div class="spinner-border spinner-border-sm me-1 mt-1 text-secondary"></div>${loadingText}</div>`;
    } else {
      spinnerStatus = `<div class="d-flex justify-content-center"><div class="spinner-border spinner-border-sm me-1 mt-1 text-light"></div>${loadingText}</div>`;
    }
    dummyButton.innerHTML = spinnerStatus;
  } else {
    dummyButton.innerHTML = button.innerHTML;
  }

  // Hide the original button.
  button.classList.add("d-none");

  // Return the dummy button
  return dummyButton;
}

/**
 * Register a dynamically-inserted button element with submit protect.
 *
 * @param {HTMLButtonElement} buttonElement - the button element
 */
export function registerSubmitButton(buttonElement) {
  buttonElement.dataset.clicked = false;
  buttonElement.addEventListener("click", (event) => {
    event.currentTarget.dataset.clicked = true;
  });
}

/**
 * Register a form (if not submit-protect-disabled) for mulitple-submit protection.
 *
 * This function attaches a submit listener that, upon firing, replaces all submit
 * buttons (embedded and externally-referred-to via the attribute form=) with dummy
 * buttons that display "processing" copy and a spinner, and are disabled.
 *
 * This function also registers listeners for the custom event `cancel-submit-protect`
 * which cancels submit protection and puts the original buttons back; and
 * `invoke-submit-protect` which will invoke the submit protect behavior without a form
 * submit.
 *
 * @param {HTMLFormElement} form - the form to add submit protection to
 */
export function registerFormSubmitProtection(form) {
  if (form.dataset.disableSubmitProtect) {
    return;
  }

  const getSubmitButtons = () =>
    [
      ...form.querySelectorAll('[type="submit"]'),
      ...document.querySelectorAll(
        `[type="submit"][form="${form.getAttribute("id") || "nonsenseIDToCaptureNothing"}"]`,
      ),
      ...document.querySelectorAll(".faux-submit-button"),
    ].filter((element) => element.dataset.disableSubmitProtect !== "1");

  const submitButtons = getSubmitButtons();

  submitButtons.forEach((submitButton) => {
    submitButton.dataset.clicked = false;
    submitButton.addEventListener("click", (event) => {
      event.currentTarget.dataset.clicked = true;
    });
  });

  const submitHandler = () => {
    if (form.dataset.submitProtectActive) {
      return;
    }

    form.dataset.submitProtectActive = "1";

    const currentSubmitButtons = getSubmitButtons();
    currentSubmitButtons.forEach((element) => {
      swapButtonForDummy(element);
    });

    // Bind an (optional) function to event "cancel-submit-protect"
    [
      "cancel-submit-protect",
      "htmx:responseError",
      "htmx:sendError",
      "htmx:timeout",
      "htmx:afterRequest",
    ].forEach((evtName) => {
      form.addEventListener(evtName, () => {
        if (!document.body.contains(form)) {
          return;
        }

        const submitButtonsForCancel = getSubmitButtons();
        const dummyButtons = submitButtonsForCancel.filter(
          (element) => element.dataset.isDummy === "true",
        );
        const nonDummyButtons = submitButtonsForCancel.filter(
          (element) => element.dataset.isDummy !== "true",
        );
        dummyButtons.forEach((dummyButton) => {
          dummyButton.remove();
        });
        nonDummyButtons.forEach((nonDummyButton) => {
          nonDummyButton.classList.remove("d-none");
        });

        delete form.dataset.submitProtectActive;
      });
    });

    document.body.addEventListener("global-cancel-submit-protect", () => {
      if (!document.body.contains(form)) {
        return;
      }

      const submitButtonsForCancel = getSubmitButtons();
      const dummyButtons = submitButtonsForCancel.filter(
        (element) => element.dataset.isDummy === "true",
      );
      const nonDummyButtons = submitButtonsForCancel.filter(
        (element) => element.dataset.isDummy !== "true",
      );
      dummyButtons.forEach((dummyButton) => {
        dummyButton.remove();
      });
      nonDummyButtons.forEach((nonDummyButton) => {
        nonDummyButton.classList.remove("d-none");
      });

      delete form.dataset.submitProtectActive;
    });

    currentSubmitButtons.forEach((currentSubmitButton) => {
      currentSubmitButton.dataset.clicked = false;
    });
  };

  form.addEventListener("submit", submitHandler);
  form.addEventListener("invoke-submit-protect", submitHandler);
  form.addEventListener("htmx:beforeSend", submitHandler);
}

/**
 * Swaps the clicked button for a dummy button and returns a callback to un-swap it when
 * the calling context deems the form submit as complete.
 *
 * Meant to be used with multiple-submit protection for forms via the
 * registerFormSubmitProtection function.
 *
 * @param {HTMLButtonElement} buttonElement - the button to be disabled while the submit happens
 * @returns {Function} a function that restores the button state to pre-submit
 */
export function handleAjaxFormSubmitProtection(buttonElement) {
  buttonElement.dataset.clicked = "1";

  const dummyButton = swapButtonForDummy(buttonElement);

  return () => {
    buttonElement.classList.remove("d-none");
    buttonElement.dataset.clicked = null;
    dummyButton.remove();
  };
}

/**
 * Function that creates and dispatches a DOM event in a browser-agnostic style.
 *
 * This function supports all modern browsers and IE9+.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events
 *
 * @param {EventTarget} element - target element to dispatch the event to
 * @param {string} eventName - name of the event to dispatch
 * @param {object} eventPayload - a payload to embed in the event.
 * @returns {Event} the event that was created and dispatched
 */
export function sendBrowserAgnosticEvent(element, eventName, eventPayload = null) {
  let event;

  if (typeof Event === "function") {
    event = new Event(eventName, { bubbles: true, cancelable: true });
  } else {
    event = document.createEvent("Event");
    event.initEvent(eventName, true, true);
  }

  event.payload = eventPayload;

  element.dispatchEvent(event);

  return event;
}

/**
 * Initialize the "animated placeholder form field" label behavior.
 *
 * @deprecated in favor of Bootstrap's floating labels
 * @param {Element} field - the field to add the animated placeholder to
 */
export function initAnimatedPlaceholderFormField(field) {
  const events = ["keyup", "input", "change", "paste", "fill", "reset"];
  const fieldWrapper = field.closest(".el-animated-placeholder-label-input");

  if (!fieldWrapper) {
    return;
  }

  if (field.dataset.initialized) {
    return;
  }

  if (field.value) {
    fieldWrapper.classList.add("filled");
  }

  events.forEach((e) => {
    field.addEventListener(e, () => {
      if (field.value) {
        fieldWrapper.classList.add("filled");
      } else {
        fieldWrapper.classList.remove("filled");
      }
    });
  });

  if (field.value) {
    fieldWrapper.classList.add("filled");
  }

  document.addEventListener("pageshow", () => {
    if (field && field.value && fieldWrapper) {
      fieldWrapper.classList.add("filled");
    }
  });

  field.dataset.initialized = "1";
}

/**
 * Get all implementing form fields for the animated placeholder field behavior and
 * initialize them.
 *
 * @deprecated in favor of Bootstrap's floating labels
 */
export function initAnimatedPlaceholderFormFields() {
  // Form field placeholder-to-label animation handling
  document
    .querySelectorAll(".el-animated-placeholder-label-input > input")
    .forEach((input) => {
      initAnimatedPlaceholderFormField(input);
    });
  document
    .querySelectorAll(".el-animated-placeholder-label-input > textarea")
    .forEach((input) => {
      initAnimatedPlaceholderFormField(input);
    });
}

/**
 * Given form data in the style of Django form validation errors, update
 * the DOM to include the error elements and styling for a given field.
 *
 * @param {HTMLFormElement} form - The containing Form element
 * @param {string} fieldName - The name attribute of the field
 * @param {string} error - The error text
 * @param {boolean} [before] - If the error should be inserted before the field in the DOM
 * @param {boolean} [forceShowRequired] - If the error should be shown even if it's the
 *     typical "This field is required"
 */
export function addFormError(
  form,
  fieldName,
  error,
  before = false,
  forceShowRequired = false,
) {
  let isFormField = true;
  let field = form.querySelector(`[name="${fieldName}"]`);
  if (!field) {
    isFormField = false;
    field = form.querySelector(`.${fieldName}-errors-container`);
  }

  if (field) {
    if (isFormField) {
      const existing = field.parentElement.querySelector(".errorlist");
      if (existing) {
        existing.remove();
      }
    } else {
      const existing = field.querySelector(".errorlist");
      if (existing) {
        existing.remove();
      }
    }

    if (error !== "This field is required." || forceShowRequired) {
      const errorList = document.createElement("ul");
      errorList.classList.add("errorlist");

      const errorItem = document.createElement("li");
      errorItem.innerText = error;

      errorList.appendChild(errorItem);

      if (!isFormField) {
        field.appendChild(errorList);
      } else if (before) {
        field.before(errorList);
      } else if (
        field.nextElementSibling &&
        field.nextElementSibling.tagName.toLowerCase() === "label"
      ) {
        field.nextElementSibling.after(errorList);
      } else {
        field.after(errorList);
      }
    }

    if (field && field.id) {
      const fieldRequiredWarning = document.querySelector(
        `.field-required[data-field-required-for="${field.id}"]`,
      );
      if (fieldRequiredWarning) {
        fieldRequiredWarning.classList.add("required");
      }
    }

    const parent = field.closest(".el-animated-placeholder-label-input");
    if (parent) {
      parent.classList.add("validation-failed");
    }
  }
}

/**
 * Return true if a given storage is available and supported.
 *
 * Originally from https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
 *
 * @param {string} storageType - The type of the storage to check
 * @returns {boolean} if the given storage is available and supported
 */
export function storageAvailable(storageType) {
  let storage;
  try {
    storage = window[storageType];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (error) {
    return (
      error instanceof DOMException &&
      // everything except Firefox
      (error.code === 22 ||
        // Firefox
        error.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        error.name === "QuotaExceededError" ||
        // Firefox
        error.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

/**
 * Emit a custom event to Google Analytics via the GTM datalayer.
 *
 * @param {string} label - the label of the Google Analytics Event
 * @param {string} category - the category of the Google Analytics Event
 * @param {string} action - the action of the Google Analytics Event
 * @param {Function} [callback] - function to run once the Event is tracked
 */
export function trackCustomUserAction(label, category, action, callback = undefined) {
  window.dataLayer.push({
    event: "customUserAction",
    label,
    action,
    category,
    eventCallback: callback,
  });
}

/**
 * Get whether the given element is visible in the viewport.
 *
 * @param {Element} el - an Element
 * @param {boolean} [xAxis] - consider the X axis
 * @param {boolean} [yAxis] - consider the Y axis
 * @returns {boolean} whether the element is visible.
 */
export function isInViewport(el, xAxis = true, yAxis = true) {
  const rect = el.getBoundingClientRect();
  return (
    ((rect.top >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) ||
      !yAxis) &&
    ((rect.left >= 0 &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)) ||
      !xAxis)
  );
}

/**
 * Initialize btn-copy clipboard functionality.
 *
 * @param {Element} [modalElement] - Is the target in a Modal?
 * @param {string} [customTargetSelector] - Custom selector for clipboard target.
 */
export function initClipboard(modalElement = null, customTargetSelector = null) {
  const clipboardOptions = {};
  let target = ".btn-copy";

  if (modalElement) {
    clipboardOptions.container = modalElement;

    // If we're provided an inModal that contains the copy link in question,
    // We have to specify that this ClipboardJS instance should only target
    // .btn-copy instances that are children thereof.
    target = modalElement.querySelectorAll(".btn-copy");
  }

  if (customTargetSelector) {
    target = customTargetSelector;
  }

  const clipboard = new ClipboardJS(target, clipboardOptions);
  clipboard.on("success", (event) => {
    const copyTextToUpdate = event.trigger.getElementsByClassName("copy-cta-text");

    if (copyTextToUpdate.length) {
      Array.prototype.forEach.call(copyTextToUpdate, (element) => {
        const copyText = element;
        copyText.innerText = "Copied!";
      });
    } else {
      const eventTrigger = event.trigger;
      eventTrigger.innerText = "Copied!";
    }
  });

  document.querySelectorAll(".sharing-url").forEach((sharingUrl) => {
    sharingUrl.addEventListener("click", (event) => {
      event.target.classList.add("bg-highlight");
    });
  });
}

/**
 * Initialize all Bootstrap Tooltips on a page.
 */
export function initPopovers() {
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
  [...popoverTriggerList].map((popoverTriggerEl) => new Popover(popoverTriggerEl));
}

/**
 * Initialize all Bootstrap Tooltips on a page.
 */
export function initTooltips() {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  [...tooltipTriggerList].map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
}

/**
 * Initialize form dirty (edited) state tracking and page-navigation warning
 * notification behavior when there is an unsaved form.
 *
 * @param {object} [props] - the page props
 * @param {HTMLFormElement} [formToTrack] - the form to track dirty state on
 */
export function initDirtyTracker(props = null, formToTrack = null) {
  const isDirty = (form) => {
    if (form.dataset.forceSubmit) {
      return false;
    }

    return [...form.elements].some((e) => {
      if (!e) {
        return false;
      }

      if (props && props.requireVisible && e.offsetParent === null) {
        return false;
      }

      if (e.getAttribute("data-dirty-track-ignore")) {
        return false;
      }

      if (!e.getAttribute("name")) {
        return false;
      }

      if (e.type === "checkbox" || e.type === "radio") {
        return e.checked !== e.defaultChecked;
      }

      if (
        e.type === "hidden" ||
        e.type === "password" ||
        e.type === "text" ||
        e.type === "textarea"
      ) {
        if (
          props &&
          props.fundraisingGoalCleave &&
          props.fundraisingGoalCleave.element === e
        ) {
          return props.fundraisingGoalCleave.getRawValue() !== e.defaultValue;
        }

        return e.value !== e.defaultValue;
      }

      if (e.type === "select-one" || e.type === "select-multiple") {
        if (e.classList.contains("no-changes")) {
          return false;
        }

        return [...e.options].some(
          (o) => o.value && o.value.length && o.selected !== o.defaultSelected,
        );
      }

      return false;
    });
  };

  const form = formToTrack || document.querySelector('form[data-dirty-track="true"]');

  if (form) {
    const listener = (e) => {
      if (form.getAttribute("data-dirty-track-is-submitting")) {
        form.removeAttribute("data-dirty-track-is-submitting");
      } else if ((form && isDirty(form)) || (props && props.alwaysWarnDirtyForm)) {
        const message =
          (props && props.dirtyFormMessage) ||
          form.dataset.dirtyFormMessage ||
          "You have unsaved changes.";
        (e || window.event).returnValue = message;
        (e || window.event).preventDefault();

        return message;
      }

      return true;
    };

    form.addEventListener("submit", () => {
      form.setAttribute("data-dirty-track-is-submitting", "1");
    });

    window.addEventListener("beforeunload", listener);
  }
}

const ACCEPTED_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png"];

/**
 * Compare a selected File (from a file input) to the provided accept types on the input
 * element. If it is a match, return the file. If it is not, pop a browser alert and
 * return null.
 *
 * @param {HTMLInputElement} input - the input to read from
 * @returns {(File|null)} a File if the file's type is valid otherwise null
 */
export function readValidFileFromInput(input) {
  const { files } = input;
  if (files && files[0]) {
    const mediaType = files[0].type;

    if (ACCEPTED_MIME_TYPES.includes(mediaType)) {
      return files[0];
    }

    input.value = null;

    window.alert(
      "The photo you've chosen is of an incompatible file type. Please upload a JPEG or PNG file.",
    );
  }

  return null;
}

/**
 * Make a simple HTML element with the given parameters
 *
 * @param {string} tagName - the tag name to create
 * @param {object} attributes - any HTML attributes to put on the tag
 * @param {string|null} innerText - the innerText of the tag being created
 * @returns {HTMLElement} the created HTML element.
 */
export function makeSimpleElement(tagName, attributes, innerText = null) {
  const el = document.createElement(tagName);
  Object.keys(attributes).forEach((k) => {
    el.setAttribute(k, attributes[k]);
  });

  if (innerText) {
    el.innerText = innerText;
  }

  return el;
}

/**
 * Append the params-described element as a child of the given parentNode.
 *
 * @param {Node} parentNode - the parent node to append to.
 * @param {string} childNodeTagName - the node type (tag name) of the new child node
 * @param {object} childNodeAttributes - any HTML attributes to attach to the new child node
 * @param {string|null} childNodeInnerText - the inner text of the new child node
 * @returns {HTMLElement} - the new element that was appended.
 */
export function appendSimpleChildElement(
  parentNode,
  childNodeTagName,
  childNodeAttributes,
  childNodeInnerText = null,
) {
  const child = makeSimpleElement(
    childNodeTagName,
    childNodeAttributes,
    childNodeInnerText,
  );
  parentNode.appendChild(child);

  return child;
}

/**
 * Return an HTMLCollection of elements from a given string of HTML.
 *
 * NOTE: You must use the splat (...) operator to unroll the collection into `.append()`
 * or into a new array to be able to index-access elements (like, say, if there's one
 * top-level element and you want to access it with `[0]`).
 *
 * WARNING: Only use this with non-UGC content!
 *
 * @param {string} html - the HTML content to generate elements from
 * @returns {HTMLCollection} the generated elements
 */
export function generateElements(html) {
  const template = document.createElement("template");
  template.innerHTML = html.trim();
  return template.content.children;
}

/**
 * Return an object representing the coordinates of a given element relative to the
 * document.
 *
 * @param {HTMLElement} element - the element to get coordinates for
 * @returns {object} the coordinates of the element as properties `top` and `left`
 */
export function getElementDocumentOffset(element) {
  const domRect = element.getBoundingClientRect();
  const docElem = document.documentElement;
  return {
    top: domRect.top + window.scrollY - docElem.clientTop,
    left: domRect.left + window.scrollX - docElem.clientLeft,
  };
}

/**
 * Get the previous sibling matching the given selector.
 *
 * @param {Element} elem - the provided element to check for a sibling of.
 * @param {string} selector - the selector to match a sibling for.
 * @returns {Element|null} - if there's a match, the matching sibling is returned.
 */
export function getPreviousSiblingMatchingSelector(elem, selector) {
  let sibling = elem.previousElementSibling;
  if (!selector) return sibling;
  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.previousElementSibling;
  }

  return null;
}

/**
 * Get the next sibling matching the given selector.
 *
 * @param {Element} elem - the provided element to check for a sibling of.
 * @param {string} selector - the selector to match a sibling for.
 * @returns {Element|null} - if there's a match, the matching sibling is returned.
 */
export function getNextSiblingMatchingSelector(elem, selector) {
  let sibling = elem.nextElementSibling;
  if (!selector) return sibling;
  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.nextElementSibling;
  }

  return null;
}

/**
 * Return a Promise that resolves after a given number of milliseconds.
 *
 * @param {number} milliseconds - the amount of time to sleep in milliseconds
 * @returns {Promise} - a Promise representing the sleep action
 */
export function sleep(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

/**
 * Remove a given element's d-none CSS class, and fade it in.
 *
 * @param {HTMLElement} element - an element to fade out
 * @param {number} [delay] - the amount of time to delay the animation by in milliseconds
 * @param {string} [duration] - the duration override of the animation (ex. '500ms')
 */
export async function fadeIn(element, delay = 0, duration = undefined) {
  await sleep(delay);
  element.classList.remove("d-none");
  await animateCSS(element, "fadeIn", duration);
}

/**
 * Fade a given element out and set its display CSS property to none;
 *
 * @param {HTMLElement} element - an element to fade out
 * @param {number} [delay] - the amount of time to delay the animation by in milliseconds
 * @param {string} [duration] - the duration override of the animation (ex. '500ms')
 */
export async function fadeOut(element, delay = 0, duration = undefined) {
  await sleep(delay);
  await animateCSS(element, "fadeOut", duration);
  element.classList.add("d-none");
}
