import { storageAvailable } from "../core/utils";

const LOCAL_STORAGE_KEY = "dismissed-announcements";

export default function initAnnouncements() {
  const isStorageAvailable = storageAvailable("localStorage");

  let localStorageData = [];
  if (isStorageAvailable) {
    localStorageData = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)) || [];
  }

  const announcementsContainer = document.querySelector(".announcements");
  const announcements = announcementsContainer.querySelectorAll(".announcement");
  const currentUUIDs = [...announcements].map((announcement) =>
    announcement.getAttribute("data-uuid"),
  );
  const dismissedUUIDs = localStorageData.filter((x) => currentUUIDs.includes(x));
  const activeUUIDs = currentUUIDs.filter((x) => !dismissedUUIDs.includes(x));

  if (activeUUIDs) {
    dismissedUUIDs.forEach((uuid) => {
      announcementsContainer.querySelector(`[data-uuid="${uuid}"]`).remove();
    });
    announcementsContainer.classList.remove("d-none");
  }

  announcements.forEach((announcement) => {
    announcement.querySelector("img").addEventListener("click", () => {
      dismissedUUIDs.push(announcement.getAttribute("data-uuid"));
      if (isStorageAvailable) {
        window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(dismissedUUIDs));
      }
      announcement.remove();
    });
  });
}
