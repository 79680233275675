import Alert from "bootstrap/js/dist/alert"; // eslint-disable-line no-unused-vars
import Modal from "bootstrap/js/dist/modal"; // eslint-disable-line no-unused-vars
import htmx from "htmx.org";
import Cookies from "js-cookie";

import config from "./config";
import initAnnouncements from "../components/Announcements";
import initNavigation from "../components/Navigation";
import initNotifications from "../components/Notifications";
import {
  fadeIn,
  fadeOut,
  initAnimatedPlaceholderFormFields,
  isVisible,
  registerFormSubmitProtection,
  scrollToElement,
} from "./utils";

/**
 * Set our header's back button to use window.backLinkTargetCallback if it exists.
 * Otherwise, use the normal browser behavior.
 */
function initNavHistoryBackButton() {
  window.backLinkTargetCallback = null;

  document.querySelectorAll('[data-nav-history-back="1"]').forEach((backLink) => {
    backLink.addEventListener("click", (event) => {
      if (window.backLinkTargetCallback) {
        window.backLinkTargetCallback(event);
      } else {
        window.history.back();
      }
    });
  });
}

/**
 * Add a class to potrait-oriented images with the cover-reposition class to improve
 * their positioning.
 *
 * We found that covering portrait photos in landscape containers "zoomed" in on an area
 * at the center that generally wasn't the subject matter, so we chose an arbitrary
 * positioning for portrait photos that seemed to match a higher percentage of subject
 * centers.
 */
function initObjectFitCoverPortraitBias() {
  const repositionableImgs = document.querySelectorAll("img.cover-reposition");
  const assess = (img) => {
    if (img.complete && img.naturalHeight !== 0) {
      const imgNaturalAspect = img.naturalWidth / img.naturalHeight;
      const imgElementAspect = img.offsetWidth / img.offsetHeight;

      if (imgElementAspect / imgNaturalAspect > 1.5) {
        img.classList.add("portrait");
      }
    }
  };

  repositionableImgs.forEach((img) => {
    img.addEventListener("load", () => {
      assess(img);
    });
    assess(img);
  });
}

/**
 * Initialize the toggles and overlays for our mobile header.
 */
function initMobileHeader() {
  const toggleScroll = (toggler) => {
    if (toggler.classList.contains("active")) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };

  const mobileNavToggler = document.querySelector(".el-navbar-mobile-toggler-nav");
  const mobileNavOverlay = document.querySelector(".el-nav-overlay-nav");
  const mobileNotificationsToggler = document.querySelector(
    ".el-navbar-mobile-toggler-notifications",
  );
  const mobileNotificationsOverlay = document.querySelector(
    ".el-nav-overlay-notifications",
  );
  const beaconLinks = document.querySelectorAll(".support-chat-beacon-link");

  let mobileNoficationsCloseLines;
  let notificationIcon;
  if (mobileNotificationsToggler) {
    mobileNoficationsCloseLines = mobileNotificationsToggler.querySelectorAll(
      ".notifications-close-line",
    );
    notificationIcon = mobileNotificationsToggler.querySelector(".notification-icon");
  }

  const closeMobileNotificationsMenu = () => {
    mobileNotificationsOverlay.classList.remove("open");
    mobileNotificationsToggler.classList.remove("active");
    mobileNoficationsCloseLines.forEach((line) => line.classList.add("d-none"));
    notificationIcon.classList.remove("d-none");
  };

  const closeMobileNavigation = () => {
    mobileNavOverlay.classList.remove("open");
    mobileNavToggler.classList.remove("active");
    toggleScroll(mobileNavToggler);
  };

  const openMobileNotificationsMenu = () => {
    mobileNotificationsOverlay.classList.add("open");
    mobileNotificationsToggler.classList.add("active");
    mobileNoficationsCloseLines.forEach((line) => line.classList.remove("d-none"));
    notificationIcon.classList.add("d-none");
    closeMobileNavigation();
  };

  const openMobileNavigation = () => {
    mobileNavToggler.classList.add("active");
    mobileNavOverlay.classList.add("open");
    if (mobileNotificationsToggler) {
      closeMobileNotificationsMenu();
    }
  };

  const toggleMobileNotificationsMenu = () => {
    if (mobileNotificationsOverlay.classList.contains("open")) {
      closeMobileNotificationsMenu();
    } else {
      openMobileNotificationsMenu();
    }
  };

  const toggleMobileNavigation = () => {
    if (mobileNavOverlay.classList.contains("open")) {
      closeMobileNavigation();
    } else {
      openMobileNavigation();
    }
    toggleScroll(mobileNavToggler);
  };

  if (mobileNotificationsToggler) {
    mobileNotificationsToggler.addEventListener("click", toggleMobileNotificationsMenu);
  }

  if (mobileNavToggler) {
    mobileNavToggler.addEventListener("click", toggleMobileNavigation);
  }

  beaconLinks.forEach((beaconLink) => {
    beaconLink.addEventListener("click", () => {
      if (mobileNavToggler) {
        mobileNavToggler.classList.remove("active");
      }

      if (mobileNavOverlay) {
        mobileNavOverlay.classList.remove("open");
      }
    });
  });
}

/**
 * Initialize hiding/showing opted-in elements on form focus/blur.
 */
const initMobileKeyboardFocusSupport = () => {
  document.body.addEventListener("focusin", (event) => {
    // Skip non-mobile viewports
    if (window.innerWidth >= 768) {
      return;
    }

    if (
      ["input", "textarea", "checkbox", "radio"].includes(
        event.target.tagName.toLowerCase(),
      ) ||
      event.target.id.includes("medium-editor")
    ) {
      document
        .querySelectorAll('[data-hide-on-form-focus="true"]')
        .forEach(async (element) => {
          await fadeOut(element, 0, "200ms");
          element.classList.add("d-none");
        });
    }
  });

  document.body.addEventListener("focusout", (event) => {
    // Skip non-mobile viewports
    if (window.innerWidth >= 768) {
      return;
    }

    if (
      ["input", "textarea", "checkbox", "radio"].includes(
        event.target.tagName.toLowerCase(),
      ) ||
      event.target.id.includes("medium-editor")
    ) {
      document
        .querySelectorAll('[data-hide-on-form-focus="true"]')
        .forEach(async (element) => {
          await fadeIn(element, 0, "200ms");
        });
    }
  });
};

/**
 * Run all site-wide code.
 */
export function initSiteWide() {
  if (!config.debug && window.Osano && window.Osano.cm) {
    ["osano-cm-initialized", "osano-cm-consent-saved"].forEach((event) => {
      window.Osano.cm.addEventListener(event, (consentObj) => {
        if (consentObj) {
          Cookies.set("ever-loved-cookie-consent", JSON.stringify(consentObj));
        }
      });
    });
  }

  if (!Object.prototype.hasOwnProperty.call(window, "htmx")) {
    window.htmx = htmx;
  }

  // NOTE: regex sourced from http://detectmobilebrowsers.com/
  window.isOnMobile = false;
  ((agent) => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        agent,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        agent.substr(0, 4),
      )
    ) {
      window.isOnMobile = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);

  // NOTE: this is used by various scripts of ours
  window.hideSupportChatOnMobile = false;

  initMobileHeader();
  initMobileKeyboardFocusSupport();

  initNavigation();
  initNotifications();
  initAnnouncements();

  // Initialize form submit protection for all forms currently on the page
  document.querySelectorAll("form").forEach((form) => {
    registerFormSubmitProtection(form);
  });

  document.body.addEventListener("htmx:afterSettle", (event) => {
    if (
      event.detail &&
      event.detail.elt &&
      (event.detail.elt.querySelector("form") || event.detail.elt.tagName === "FORM")
    ) {
      registerFormSubmitProtection(
        event.detail.elt.tagName === "FORM"
          ? event.detail.elt
          : event.detail.elt.querySelector("form"),
      );
    }
  });

  initAnimatedPlaceholderFormFields();
  initObjectFitCoverPortraitBias();
  initNavHistoryBackButton();

  config.whenReadyFunctions.push(
    () => {
      // Some pages, like the memories page, make their errorlists visible during the
      // execution of the their code. Additionally, there may be multiple errorlists on a
      // page with some of them being empty.
      const errorList = [...document.querySelectorAll(".errorlist")].find((element) =>
        isVisible(element),
      );
      const invalidFormControl = document.querySelector(".form-control.is-invalid");
      const elementToScrollTo = errorList || invalidFormControl;

      // Allow scrolls to errorList or invalidFormControl only if all alert-error
      // messages also have the allow-form-error-auto-scroll class.
      if (
        elementToScrollTo &&
        !document.querySelectorAll(
          ".messages-container .alert-error:not(.allow-form-error-auto-scroll)",
        ).length
      ) {
        const privateContentContainer = elementToScrollTo.closest(
          ".private-content-container",
        );
        scrollToElement(
          elementToScrollTo,
          -(window.innerHeight / 2),
          false,
          !window.isOnMobile && privateContentContainer
            ? privateContentContainer
            : document.scrollingElement,
        );
      }
    },
    () => {
      // Remove the messages container if no messages remain
      document.querySelectorAll(".messages-container").forEach((messagesContainer) => {
        const liveAlertList = messagesContainer.getElementsByClassName("alert");
        const alertList = messagesContainer.querySelectorAll(".alert");
        alertList.forEach((alert) => {
          alert.addEventListener("closed.bs.alert", () => {
            if (liveAlertList.length === 0) {
              messagesContainer.remove();
            }
          });
        });
      });
    },
  );
}

/**
 * Run all site-wide code that we want to execute after all of our scripts have loaded.
 */
export function initSiteWideOnReady() {
  config.whenReadyFunctions.forEach((func) => func());
}
